<template>
  <!--异常订单-->
  <div style="padding: 20px;">
    <div v-if="Orderdetails == false">
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键字:">
                    <el-input
                      clearable
                      v-model="where.keyword"
                      placeholder="订单号/案件号/客户/客户电话/客户/服务人员"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="受理人:">
                    <el-input
                      clearable
                      v-model="where.acceptor_nickname"
                      placeholder="请输入受理人"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="8" :md="12">
                  <el-form-item label="下单时间:">
                    <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="where.times"
                      type="daterange"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                    <!--<DatePicker v-model="where.order_time" :transfer="true" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/>-->
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12" style="text-align: center;">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <!--<div class="Datalist-botton" style="margin-left: 20px">-->
              <!--  <el-button class="custom-button" @click="CreatePenaltyOrder()">-->
              <!--    &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
              <!--    <i class="el-icon-xiadan" />-->
              <!--    <span style="vertical-align: middle">下单</span>-->
              <!--  </el-button>-->
              <!--</div>-->
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!--<div class="table_div">-->
            <!--  <div v-for="(item,index) in activeList" :key="index" class="table_div_kuai" :class="{table_div_kuai1:index==isShow}" @click="clickactive(item,index)">-->
            <!--    <el-badge :value="item.value" class="item">-->
            <!--    <span>-->
            <!--      {{item.label}}-->
            <!--    </span>-->
            <!--    </el-badge>-->
            <!--  </div>-->
            <!--</div>-->

            <!--<div class="table_div">-->
            <!--  <div v-for="(item,index) in activeList1" :key="index" class="table_div_kuai" :class="{table_div_kuai1:index==isShow1}" @click="clickactive1(item,index)">-->
            <!--    <el-badge :value="0" class="item">-->
            <!--    <span>-->
            <!--      {{item.label}}-->
            <!--    </span>-->
            <!--    </el-badge>-->
            <!--  </div>-->
            <!--</div>-->

            <div class="table_div">
              <div v-for="(item,index) in activeList2" :key="index" class="table_div_kuai" :class="{table_div_kuai1:index==isShow2}" @click="clickactive2(item,index)">
                <el-badge :hidden="item.value === '' ? false : true" :value="item.value" class="item">
                  <span>
                    {{item.label}}
                  </span>
                </el-badge>
              </div>
            </div>

            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >

              <!--异常类型-->
              <!--<template v-slot:abnormal_type= "{ scope }">-->

              <!--</template>-->

              <!--&lt;!&ndash;异常信息&ndash;&gt;-->
              <!--<template v-slot:abnormal_msg= "{ scope }">-->
              <!--  <span v-if="scope.row"></span>-->
              <!--</template>-->

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="success" v-if="isShow2 == 1" @click="AddFollowUp(scope.row)">催单处理</el-link>

                  <!--订单来源投诉-->
                  <el-link :underline="false" type="success" v-if="isShow2 == 2" @click="opencomplaint(scope.row)">投诉处理</el-link>

                  <!--取消待审核-->
                  <el-link :underline="false" type="success" v-if="isShow2 == 3" @click="openreview(scope.row)">审核</el-link>

                  <!--失败待审核-->
                  <el-link :underline="false" type="success" v-if="isShow2 == 4" @click="openfailreview(scope.row)">审核</el-link>

                <!--空驶待审核-->
                <el-link :underline="false" type="success" v-if="isShow2 == 5" @click="openkong(scope.row)">审核</el-link>

                <!--报备审核 审核-->
                <el-link :underline="false" type="success" v-if="isShow2 == 6" @click="openreporting(scope.row)">审核</el-link>

                <!--  回访投诉待处理-->
                <el-link :underline="false" type="success" v-if="isShow2 == 7" @click="ReturnVisit(scope.row)">投诉处理</el-link>

                <!--聚合失败待处理-->
                <el-link :underline="false" type="success" v-if="isShow2 == 8" @click="openfailure(scope.row)">处理</el-link>

                <el-link :underline="false" type="primary" v-if="isShow2 == 3 || isShow2 == 4 || isShow2 == 5 || isShow2 == 6" @click="distributeLeaflets(scope.row)">改派</el-link>


                <!--<el-link :underline="false" type="success" v-if="isShow2 == 1 || isShow2 == 2" @click="ReplyWithRemarks(scope.row)">回复</el-link>-->
                <el-link :underline="false" type="warning" @click="Todetails(scope.row)" v-auths="[`${$config.uniquePrefix}orderModule:info:info`]">详情</el-link>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <!-- 订单详情模块 -->
    <!--<abnormalOrder-details v-if="AbnormalOrderdetails == true" :data="detailsData" @showDetalis="ShowDetalis" />-->

    <!-- 订单详情模块 -->
    <order-details v-if="Orderdetails == true" :data="Detailscurrent" :dict="dict" @showDetalis="ShowDetalis"></order-details>

    <!-- 添加跟进弹窗 -->
    <addFollowUp-edit v-if="showaddFollowUp === true" :data="addFollowUpcurrent" :visible.sync="showaddFollowUp" @done="getList"/>

    <!--备注回复-->
    <replyWithRemarks-edit v-if="showreplyWithRemarksEdit == true":data="replyWithRemarksEditcurrent" :visible.sync="showreplyWithRemarksEdit" @done="getList"/>

    <!--添加回访弹窗-->
    <addReturnVisit-edit v-if="showaddReturnVisit === true" :data="addReturnVisitcurrent" :dict="dict" :visible.sync="showaddReturnVisit" :isShowtousu="isShowtousu" @done="getList"/>

      <!--订单来与投诉处理弹窗-->
      <complaint-edit v-if="showcomplaint === true" :data="complaintcurrent" :visible.sync="showcomplaint" @done="getList"/>

      <!--取消审核-->
      <cancel-review v-if="showreview === true" :data="reviewcurrent" :visible.sync="showreview" @done="getList"/>

      <!--失败待审核 审核-->
      <fail-review v-if="showfail === true" :data="failcurrent" :visible.sync="showfail" @done="getList"/>

    <!--空驶待审核 审核-->
    <kongtravel-edit v-if="showkong === true" :data="kongcurrent" :visible.sync="showkong" @done="getList"/>

    <!--聚合失败审核 处理-->
    <failure-handling v-if="showfailure === true" :data="failurecurrent" :visible.sync="showfailure" @done="getList"/>

    <!--报备待处理 审核-->
    <reporting-edit v-if="showreporting === true" :data="reportingcurrent" :visible.sync="showreporting" @done="getList"/>

    <!--派单弹窗-->
    <reassignment-edit v-if="showreassignment === true" :data="reassignmentcurrent" :dict="dict" :visible.sync="showreassignment" :repairshop="repairshop"  @freshen="isfreshen"/>

  </div>
</template>

<script>
// 引入订单详情组件
import OrderDetails from '../details/index.vue'
// 引入订单详情组件
// import AbnormalOrderDetails from '../abnormalOrderDetails/index.vue'
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 引入添加跟进弹窗
import AddFollowUpEdit from '../details/components/addFollowUp-edit.vue'
// 引入回复
import ReplyWithRemarksEdit from '../details/components/replyWithRemarks-edit.vue'
// 引入回访
import AddReturnVisitEdit from '../pendingFollowUp/components/addReturnVisit-edit.vue'
// 引入订单来源投诉处理
import ComplaintEdit from './components/complaint-edit.vue'
// 引入取消审核
import CancelReview from './components/cancel-review.vue'
// 引入失败待审核 审核按钮
import FailReview from './components/fail-review.vue'
// 引入空驶审核
import KongtravelEdit from './components/kongtravel-edit.vue'
// 引入聚合失败审核
import FailureHandling from './components/failure-handling.vue'
// 引入报备审核
import ReportingEdit from './components/reporting-edit.vue'
// 引入派单弹窗
import ReassignmentEdit from '../toBeDispatched/components/reassignment-edit.vue'

// 权限
import {ShowTable} from '@/utils'

import Vue from "vue";
// 引入的接口
import {getabnormal, header_statistics,order_list_header} from "@/api/orderModule";

export default {
  // 组件生效
  components: {
    PackageSortElement,
    // AbnormalOrderDetails,
    AddFollowUpEdit,
    ReplyWithRemarksEdit,
      ComplaintEdit,
      CancelReview,
      FailReview,
    FailureHandling,
    KongtravelEdit,
    AddReturnVisitEdit,
    ReportingEdit,
    ReassignmentEdit,
    OrderDetails
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
        handle_tabs:'',
        overtime_tabs:'',
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "车牌号",
          // type: "index",
          // width: "70"
          prop: "license_plate",
          isShow: true,
          fixed:'left',
        },
        {
          label: "异常类型",
          // type: "index",
          // width: "70"
          prop: "abnormal_type",
          isShow: true
        },
        {
          label: "异常信息",
          prop: "abnormal_msg",
          isShow: true
        },
        {
          label: "生成时间",
          prop: "order_time",
          isShow: true
        },
        {
          label: "处理时间",
          prop: "handler_time",
          isShow: true
        },
        {
          label: "处理结果",
          prop: "explain_msg",
          isShow: true
        },
        {
          label: "状态",
          prop: "oam_status",
          isShow: true
        },
        {
          label: "责任人",
          prop: "acceptor_nickname",
          isShow: true
        },
        {
          label: "客户",
          prop: "channel_name",
          isShow: true
        },
        {
          label: "订单来源",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "救援类型",
          prop: "items_name",
          isShow: true
        },
        {
          label: "服务人员",
          prop: "driver_name",
          isShow: true
        },
        // {
        //   label: "操作",
        //   width: "200",
        //   slot: "operationSlot",
        //   isShow: true
        // },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      // 订单详情模块
      // detailsData:null,
      // AbnormalOrderdetails:false,

      // 订单详情模块
      Detailscurrent:null,
      Orderdetails:false,

      //选项
      isShow:0,
      activeList:[
        {
          label:'全部',
          value:0,
        },
        {
          label: '待处理',
          value:0,
        },
        {
          label: '已处理',
          value:0,
        }
      ],

      isShow1:0,
      activeList1:[
        {
          label:'全部',
          value:'',
        },
        // {
        //   label: '客户待处理'
        // },
        {
          label: '服务商处理',
          value:'',
        }
      ],

      isShow2:0,
      activeList2:[
        {
          label:'全部',
          value:'',
        },
        {
          label: '订单来源催单',
          value:'',
        },
        {
          label: '订单来源投诉',
          value:'',
        },
        {
          label: '取消待审核',
          value:'',
        },
        {
          label: '失败待审核',
          value:'',
        },
        {
          label: '空驶待审核',
          value:'',
        },
        {
          label: '报备待处理',
          value:'',
        },
        {
          label: '回访投诉待处理',
          value:'',
        },
        {
          label: '聚合失败待处理',
          value:'',
        },
      ],

      //字典
      dict: {
        type:{}
      },

      //添加跟进
      addFollowUpcurrent:null,
      showaddFollowUp:false,

      //备注回复
      replyWithRemarksEditcurrent:null,
      showreplyWithRemarksEdit:false,

      //添加回访
      addReturnVisitcurrent:null,
      showaddReturnVisit:false,
      isShowtousu:true,

      //订单来源投诉处理
      complaintcurrent:null,
      showcomplaint:false,

        //取消审核
        reviewcurrent:null,
        showreview:false,

        //失败待审核
        failcurrent:null,
        showfail:false,

      //空驶审核
      kongcurrent:null,
      showkong:false,

      //聚合失败审核
      failurecurrent:null,
      showfailure:false,

      //报备审核
      reportingcurrent:null,
      showreporting:false,

      //派单
      reassignmentcurrent:null,
      showreassignment:false,

      //是否显示附近修理厂
      repairshop:false

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '订单字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

    this.getHeader();
    this.getHeader1();
    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 点击处罚按钮按钮
    CreatePenaltyOrder(){
      // 显示创建订单模块
      this.showEdit = true;
    },

    //获取头部统计
    getHeader(){
      header_statistics().then(res => {
        console.log(res)
        // 第一行全部
        this.activeList[0].value = res.data.all;
        // 第一行待处理
        this.activeList[1].value = res.data.abnormalPending;
        // 第一行已处理
        this.activeList[2].value = res.data.abnormalProcessed;
        console.log(this.activeList,'activeList')

        //第二行废弃

        // 第三行全部
        this.activeList2[0].value = res.data.all;
        // 超时派单
        this.activeList2[1].value = res.data.overtimeSendOrders;
        //超时接单
        this.activeList2[2].value = res.data.overtimeJoinOrders;
        //超时到达
        this.activeList2[3].value = res.data.overtimeArrive;
        //超时完成
        this.activeList2[4].value = res.data.overtimeFinish;
        //超时预约
        this.activeList2[5].value = res.data.overtimeAppointment;
        //催单
        this.activeList2[6].value = res.data.overtimeReminder;

      })
    },

      // 获取自定义表头
      getHeader1() {
          order_list_header().then(res => {
              // console.log(res)
              // console.log(this.columns)
            // push表头
            res.data.forEach(item => {
              let data = {
                label: item.label,
                prop: item.field,
                isShow: true
              }
              if (this.columns.some(item => JSON.stringify(data) === JSON.stringify(item))) {
                console.log('数组中存在该对象');
              } else {
                console.log('数组中不存在该对象');
                this.columns.push(data)
              }
            })

            // 判断下是否存在了操作列 不存在则追加
            // 使用includes()方法进行判断
            // push操作列
            let data1 = {
              label: "操作",
              width: "200",
              slot: "operationSlot",
              fixed:'right',
              isShow: true
            }
            if (this.columns.some(item => JSON.stringify(data1) === JSON.stringify(item))) {
              console.log('数组中存在操作列');
            } else {
              console.log('数组中不存在操作列');
              this.columns.push(data1)
            }

          })
      },

    // 获取列表
    getList(){
      getabnormal(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    //选择时间
    datechange(e){
      this.where.order_time = e;
    },

    // 查询条件
    QueryList(){
      this.getList();
        // 调用获取自定义表头
        this.getHeader();
      this.getHeader1();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
        // 调用获取自定义表头
        this.getHeader();
      this.getHeader1();
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
        // 调用获取自定义表头
        this.getHeader();
      this.getHeader1();
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getHeader1();
        // 调用获取自定义表头
        this.getHeader();
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 删除
    del(row){
      console.log(row);
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        console.log('点击确定')
      }, "确定", 'warning');
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    // 跳转详情
    Todetails(row){
      // this.detailsData = row;
      // // 显示详情子组件
      // this.AbnormalOrderdetails = true;
      this.Detailscurrent = row;
      // 显示详情子组件
      this.Orderdetails = true;
    },
    // 接收 详情子组件传递的值
    // ShowDetalis(value){
    //   console.log(value)
    //   // 隐藏详情子组件
    //   this.AbnormalOrderdetails = false;
    //     // 调用获取自定义表头
    //     this.getHeader();
    //   this.getHeader1();
    //   this.getList();
    // },

    // 接收 详情子组件传递的值
    ShowDetalis(value){
      console.log(value)
      // 隐藏详情子组件
      this.Orderdetails = false;
      // 调用获取自定义表头
      this.getHeader();
      this.getList();
    },

    // 第一行状态点击
    clickactive(item,index){
      this.isShow = index;
      if(item.label == '待处理'){
        this.where.handle_tabs = 0;
      }else if(item.label == '已处理'){
        this.where.handle_tabs = 1;
      }else {
        this.where.handle_tabs = '';
      }
        // 调用获取自定义表头
        this.getHeader();
      this.getHeader1();
      this.getList();
    },

    clickactive1(item,index){
      this.isShow1 = index;
    },

    // 第三行状态点击
    clickactive2(item,index){
      this.isShow2 = index;
      if(item.label == '派单超时'){
        this.where.overtime_tabs = 1;
      }else if(item.label == '接单超时'){
        this.where.overtime_tabs = 2;
      }else if(item.label == '到达超时'){
        this.where.overtime_tabs = 3;
      }else if(item.label == '完成超时'){
        this.where.overtime_tabs = 4;
      }else if(item.label == '预约超时'){
        this.where.overtime_tabs = 5;
      }else if(item.label == '催单'){
        this.where.overtime_tabs = 6;
      }else {
        this.where.overtime_tabs = '';
      }
        // 调用获取自定义表头
        this.getHeader();
      this.getHeader1();
      this.getList();
    },


    //添加跟进按钮
    AddFollowUp(){
      this.showaddFollowUp = true;
      this.addFollowUpcurrent = this.data;
    },

    //回复
    ReplyWithRemarks(){
      this.showreplyWithRemarksEdit = true;
      this.replyWithRemarksEditcurrent = this.data;
    },

    //回访按钮
    ReturnVisit(row){
      this.addReturnVisitcurrent = row;
      this.showaddReturnVisit = true;
      this.isShowtousu = true;
    },

    //订单来源投诉处理
    opencomplaint(row){
      this.showcomplaint = true;
      this.complaintcurrent = row;
    },

    //取消待审核 审核
    openreview(row){
      this.showreview = true;
      this.reviewcurrent = row;
    },

    //失败待审核 审核
    openfailreview(row){
      this.showfail = true;
      this.failcurrent = row;
    },

    //空驶待审核 审核
    openkong(row){
      this.showkong = true;
      this.kongcurrent = row;
    },

    //聚合失败 处理
    openfailure(row){
      this.showfailure = true;
      this.failurecurrent = row;
    },

    // 报备审核
    openreporting(row){
      this.showreporting = true;
      this.reportingcurrent = row;
    },

    //点击改派
    distributeLeaflets(row){
      this.reassignmentcurrent = row;
      this.showreassignment = true;
    },

    isfreshen(value){
      // 调用获取自定义表头
      this.getHeader();
      this.getList();
    },

  }

}
</script>

<style lang="scss" scoped>
  .table_div{
    border-bottom: 1px solid #eeeeee;;
    margin-bottom: 20px;
  }
  .table_div_kuai{
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    color: #7b7b7b;
    margin: 10px;
    margin-bottom: 0;
    cursor: pointer;
    padding-bottom: 10px;
  }
  .table_div_kuai:hover{
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    color: #050505;
    border-bottom: 2px solid #FF9B05;
  }
  .table_div_kuai1{
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    color: #050505;
    border-bottom: 2px solid #FF9B05;
  }
</style>
